import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ButtonModule } from '@nesea/ngx-ui-kit/button';
import { FormModule, InputModule, RatingModule } from '@nesea/ngx-ui-kit/form';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ModalModule } from '@nesea/ngx-ui-kit/modal';
import { AccordionModule } from '@nesea/ngx-ui-kit/accordion';
import { AlertModule } from '@nesea/ngx-ui-kit/alert';
import { CardModule } from '@nesea/ngx-ui-kit/card';
import { TableModule } from '@nesea/ngx-ui-kit/table';
import { TabsModule } from '@nesea/ngx-ui-kit/tabs';
import { LetDirective, PushPipe } from '@ngrx/component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TypologySortPipe } from '@shared/pipes/typology-sort.pipe';
import { TimesheetComponent } from '@shared/components/timesheet/timesheet.component';
import { TimesheetSearchModalComponent } from '@shared/modals/timesheet-search-modal/timesheet-search-modal.component';
import {
  TimesheetSummaryModalComponent
} from '@shared/modals/timesheet-summary-modal/timesheet-summary-modal.component';
import {
  TimesheetActivationsModalComponent
} from '@shared/modals/timesheet-activations-modal/timesheet-activations-modal.component';
import {
  TimesheetOrderUpdateModalComponent
} from '@shared/modals/timesheet-order-update-modal/timesheet-order-update-modal.component';
import {
  TimesheetSendUploadModalComponent
} from './modals/timesheet-send-upload-modal/timesheet-send-upload-modal.component';
import { FileSaverModule } from '@nesea/ngx-ui-kit/file-saver';
import {
  TimesheetSummaryOrdersComponent
} from '@shared/modals/timesheet-summary-modal/components/timesheet-summary-orders/timesheet-summary-orders.component';
import {
  TimesheetSummaryAbsencesComponent
} from './modals/timesheet-summary-modal/components/timesheet-summary-absences/timesheet-summary-absences.component';
import {
  TimesheetSummaryOvertimesComponent
} from './modals/timesheet-summary-modal/components/timesheet-summary-overtimes/timesheet-summary-overtimes.component';
import {
  TimesheetSummaryActivationsComponent
} from './modals/timesheet-summary-modal/components/timesheet-summary-activations/timesheet-summary-activations.component';
import {
  TimesheetSummaryHistoryComponent
} from './modals/timesheet-summary-modal/components/timesheet-summary-history/timesheet-summary-history.component';
import {
  TimesheetSummaryDocumentsComponent
} from './modals/timesheet-summary-modal/components/timesheet-summary-documents/timesheet-summary-documents.component';
import {
  TimesheetOrderDeleteModalComponent
} from '@shared/modals/timesheet-order-delete-modal/timesheet-order-delete-modal.component';
import { RequestStatusPipe } from '@shared/pipes/request-status.pipe';
import { FilePreviewModalComponent } from '@shared/modals/file-preview-modal/file-preview-modal.component';
import { SanitizeUrlPipe } from '@shared/pipes/sanitize-url.pipe';
import { TranslocoDatePipe } from '@jsverse/transloco-locale';

const ngxUiKitModules = [
  AccordionModule,
  AlertModule,
  ButtonModule,
  CardModule,
  FileSaverModule,
  FormModule,
  InputModule,
  ModalModule,
  RatingModule,
  TableModule,
  TabsModule
];

const ngrxModules = [
  LetDirective,
  PushPipe
];

const components = [
  TimesheetComponent
];

const modals = [
  ConfirmModalComponent,
  TimesheetActivationsModalComponent,
  TimesheetOrderUpdateModalComponent,
  TimesheetOrderDeleteModalComponent,
  TimesheetSearchModalComponent,
  TimesheetSendUploadModalComponent,
  TimesheetSummaryModalComponent,
  TimesheetSummaryOrdersComponent,
  TimesheetSummaryAbsencesComponent,
  TimesheetSummaryOvertimesComponent,
  TimesheetSummaryActivationsComponent,
  TimesheetSummaryHistoryComponent,
  TimesheetSummaryDocumentsComponent,
  FilePreviewModalComponent
];

const standalonePipes = [
  TypologySortPipe,
  RequestStatusPipe,
  SanitizeUrlPipe,
  TranslocoDatePipe
];

@NgModule({
  declarations: [
    ...modals,
    ...components,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    FullCalendarModule,
    ...ngxUiKitModules,
    ...ngrxModules,
    ...standalonePipes
  ],
  exports: [
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    ...components,
    ...ngxUiKitModules,
    ...ngrxModules,
    ...standalonePipes
  ]
})
// TODO: Check modules
export class SharedModule { }
