export enum RequestStatusEnum {
  FINALE = 'FNL',
  COMUNICATA = 'COM',
  ATTESA_APPROVAZIONE = 'ATTAPP',
  ATTESA_APPROVAZIONE_HR = 'ATTAPPHR',
  APPROVATA = 'APP',
  APPROVATA_HR = 'APPHR',
  RIFIUTATA = 'RIF',
  RIFIUTATA_HR = 'RIFHR',
  ANNULLATA = 'ANN',
  DOC_DA_VALIDARE = 'DOCDAVAL',
  DOC_VALIDATA = 'DOCVAL',
  DOC_DA_CARICARE = 'DOCDACAR'
}
// TODO: Rename in eng
