import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { DateUtils } from '@shared/utils/date.utils';
import { ITableAction, ITableColumn } from '@nesea/ngx-ui-kit/shared';
import { IDocumentHistoryTimesheet, IDocumentRequestTimesheet } from '@shared/models/interfaces/timesheet.interface';
import { DocumentsService } from '@shared/services/documents.service';
import { filter, map, switchMap, take, tap } from 'rxjs';
import { ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { ConfirmModalComponent, IConfirmModalOutput } from '@shared/components/confirm-modal/confirm-modal.component';
import { ToastService } from '@nesea/ngx-ui-kit/toast';
import { TimesheetService } from '@shared/services/timesheet.service';


@Component({
  selector: 'nsf-timesheet-summary-documents',
  templateUrl: './timesheet-summary-documents.component.html',
  styleUrl: './timesheet-summary-documents.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimesheetSummaryDocumentsComponent implements OnInit {

  @Input() timesheetId: number;
  @Input() currentMonth: number;
  @Input() currentYear: number;
  @Input() documents: IDocumentHistoryTimesheet[];

  items: IDocumentHistoryTimesheet[] = [];
  columns: ITableColumn<IDocumentHistoryTimesheet>[] = [];
  actions: ITableAction<IDocumentHistoryTimesheet>[] = [];

  private _documentsService: DocumentsService = inject(DocumentsService);
  private _modalViewerService: ModalViewerService = inject(ModalViewerService);
  private _toastService: ToastService = inject(ToastService);
  private _timesheetService = inject(TimesheetService);
  private _cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.columns = [
      {
        id: 'date',
        label: 'LABEL.UPLOAD_DATE.TEXT',
        property: 'dataInserimento',
        getValue: (item: IDocumentHistoryTimesheet) => DateUtils.formatDate(item.dataInserimento, true)
      },
      {
        id: 'name',
        label: 'LABEL.NAME.TEXT',
        property: 'nomeFile'
      }
    ];

    this.actions = [
      { id: 'delete-action', label: 'ACTION.DELETE', icon: 'trash', onClick: (item: IDocumentHistoryTimesheet) => this._onDelete(item) },
      { id: 'preview-action', label: 'ACTION.PREVIEW', icon: 'eye', onClick: (item: IDocumentHistoryTimesheet) => this._onPreview(item) },
      { id: 'download-action', label: 'ACTION.DOWNLOAD', icon: 'download', onClick: (item: IDocumentHistoryTimesheet) =>this._onDownload(item) }
    ];

    this.items = [...this.documents];
  }
  private _onPreview(item: IDocumentHistoryTimesheet): void {
    const request: IDocumentRequestTimesheet = {
      nomeFile: item.nomeFile,
      mese: this.currentMonth + 1,
      anno: this.currentYear,
      id: item.id
    };

    this._documentsService.previewTimesheetDocument(request).pipe(
      take(1)
    ).subscribe();
  }

  private _onDownload(item:IDocumentHistoryTimesheet){
    const request: IDocumentRequestTimesheet = {
      nomeFile: item.nomeFile,
      mese: this.currentMonth + 1,
      anno: this.currentYear,
      id: item.id
    };

    this._documentsService.downloadTimesheetDocument(request).pipe(
      take(1)
    ).subscribe();
  }

  private _onDelete(item: IDocumentHistoryTimesheet) {
      const request: IDocumentRequestTimesheet = {
        nomeFile: item.nomeFile,
        id: item.id,
        mese: this.currentMonth + 1,
        anno: this.currentYear
      };

      this._modalViewerService.open(ConfirmModalComponent, {
        size: 'md',
        titleUppercase: true,
        title: 'MODAL.CONFIRM.TITLE',
        data: { message: 'MODAL.CONFIRM.MESSAGE', showNote: false }
      }).pipe(
        take(1),
        filter((output: IConfirmModalOutput) => !!output?.outcome),
        map(({ note }) => note),
        switchMap(() =>  this._documentsService.deleteTimesheetDocument(request).pipe(
          take(1),
          tap(() => this._toastService.showSuccess({
            title: 'DOCUMENTS.NOTIFICATION.SUCCESS.DELETION.TITLE',
            message: 'DOCUMENTS.NOTIFICATION.SUCCESS.DELETION.MESSAGE'
          }))
        )),
        switchMap(() => this._timesheetService.getTimesheetDocuments(this.timesheetId).pipe(
          take(1),
          tap(items => {
            this.items = [...items];
            this._cdr.markForCheck();
          })
        ))
      ).subscribe();
  }

}
