import { translateDate } from '@nesea/ngx-ui-kit/shared';
import { DateFormatOptions } from '@jsverse/transloco-locale';

const currentDate = (): Date => new Date();

const isWeekend = (date: Date | string | number): boolean => {
  let newDate: Date = new Date(date);
  return newDate.getDay() === 0 || newDate.getDay() === 6;
};

const formatDate = (date: Date | string | number, showHours?: boolean): string => {
  const options:  DateFormatOptions | undefined = !!showHours ? { hour: '2-digit', minute: '2-digit' } : undefined;
  return !!date ? translateDate(date, options) : '-';
};

const addDays = (date: Date | string | number, days: number): Date => {
  let newDate: Date = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

const currentDateByHours = (hours: number, minutes: number, seconds: number): Date => {
  const date: Date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  date.setMilliseconds(0);
  return date;
};

const dateByHours = (date: Date | number | string, hours: number, minutes: number, seconds: number): Date => {
  const newDate: Date = new Date(date);
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  newDate.setSeconds(seconds);
  newDate.setMilliseconds(0);
  return newDate;
};

const sameDate = (first: Date | number | string, second: Date | number | string): boolean => {
  const firstDate: Date = !!first ? new Date(first) : null;
  const secondDate: Date = !!second ? new Date(second) : null;
  return firstDate?.getTime() === secondDate?.getTime();
};

const firstDayOfCurrentMonth = (): Date => {
  const date: Date = new Date();
  date.setDate(1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

const lastDayOfCurrentMonth = (): Date => {
  const date: Date = new Date();
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

const firstDayOfMonth = (date: Date | number | string): Date => {
  const d: Date = new Date(date);
  d.setDate(1);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
};

const lastDayOfMonth = (date: Date | number | string): Date => {
  const d: Date = new Date(date);
  d.setMonth(d.getMonth() + 1);
  d.setDate(0);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
};

const firstDay = (): Date => {
  const date: Date = new Date();
  date.setMonth(0);
  date.setDate(1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

const lastDay = (): Date => {
  const date: Date = new Date();
  date.setMonth(11);
  date.setDate(31);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

const endOfDay = (date: Date | number | string): Date => {
  const d: Date = new Date(date);
  d.setHours(23);
  d.setMinutes(59);
  d.setSeconds(59);
  d.setMilliseconds(0);
  return d;
};

const sameMonth = (first: Date | number | string, second: Date | number | string): boolean => {
  const f: Date = new Date(first);
  const s: Date = new Date(second);
  return f.getMonth() === s.getMonth() && f.getFullYear() === s.getFullYear();
};

const daysBetween = (first: Date | number | string, second: Date | number | string, weekends?: boolean): { dayOfMonth: number, dayOfWeek: number, date: Date }[] => {
  let startDate: Date = new Date(first);
  const endDate: Date = new Date(second);
  const output: { dayOfMonth: number, dayOfWeek: number, date: Date }[] = [];

  while(startDate.getTime() <= endDate.getTime()) {
    const isWeekend: boolean = startDate.getDay() === 0 || startDate.getDay() === 6;
    if(isWeekend) {
      !!weekends && output.push({
        dayOfMonth: startDate.getDate(),
        dayOfWeek: startDate.getDay(),
        date: startDate
      });
    } else {
      output.push({
        dayOfMonth: startDate.getDate(),
        dayOfWeek: startDate.getDay(),
        date: startDate
      });
    }

    const nextStartDate: Date = new Date(startDate);
    nextStartDate.setDate(nextStartDate.getDate() + 1);
    startDate = new Date(nextStartDate);
  }
  return output;
};

const isBetween = (date: Date | number | string, startDate: Date | number | string, endDate: Date | number | string, strict: boolean = false): boolean => {
  const d: Date = new Date(date);
  const start: Date = new Date(startDate);
  const end: Date = new Date(endDate);

  return strict ? (start.getTime() < d.getTime() && end.getTime() > d.getTime()) : (start.getTime() <= d.getTime() && end.getTime() >= d.getTime());
};

const isAfter = (date: Date | number | string, compareDate: Date | number | string, strict: boolean = false): boolean => {
  const d: Date = new Date(date);
  const compare: Date = new Date(compareDate);

  return strict ? (d.getTime() > compare.getTime()) : (d.getTime() >= compare.getTime());
};

const isBefore = (date: Date | number | string, compareDate: Date | number | string, strict: boolean = false): boolean => {
  const d: Date = new Date(date);
  const compare: Date = new Date(compareDate);

  return strict ? (d.getTime() < compare.getTime()) : (d.getTime() <= compare.getTime());
};

export const DateUtils = {
  currentDate,
  isWeekend,
  formatDate,
  addDays,
  currentDateByHours,
  dateByHours,
  sameDate,
  firstDayOfCurrentMonth,
  lastDayOfCurrentMonth,
  firstDayOfMonth,
  lastDayOfMonth,
  firstDay,
  lastDay,
  endOfDay,
  sameMonth,
  daysBetween,
  isBetween,
  isBefore,
  isAfter
};
