import { Pipe, PipeTransform } from '@angular/core';
import { ITypology } from '@shared/models/interfaces/typology.interface';
import { RequestStatusEnum } from '@shared/enums/request-status.enum';

@Pipe({
  name: 'requestStatus',
  standalone: true
})
export class RequestStatusPipe implements PipeTransform {

  transform(value: ITypology<RequestStatusEnum>[]): ITypology<RequestStatusEnum>[] {
    return [...value].filter(({ code }) => code !== RequestStatusEnum.FINALE);
  }

}
