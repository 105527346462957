import { IWeekWorktime } from '@shared/models/interfaces/week-worktime.interface';
import { DateUtils } from '@shared/utils/date.utils';

const getWorktimeByDate = (workTime: IWeekWorktime[], date: Date): IWeekWorktime => {
  return workTime.find(({ startDate, endDate }, index) => {
    if(!!endDate) {
      return DateUtils.isBetween(date, startDate, endDate);
    } else {
      return DateUtils.isAfter(date, startDate);
    }
  });
};

const parseHours = (totalHours: number): string => {
  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);
  const parsedHours: string = `${hours}`;
  const parsedMinutes: string = `${minutes}`.padStart(2, '0');
  return `${parsedHours}h : ${parsedMinutes}m`;
};

export const TimesheetUtils = {
  getWorktimeByDate,
  parseHours
};

