import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { IResponse, IResultMessage } from '@core/models/interfaces/response.interface';
import {
  IAttachmentTimesheet,
  IDocumentHistoryTimesheet,
  IMealVoucher,
  IOrderTimesheet,
  ITimesheet,
  ITimesheetStatus,
  IWorkingDayTimesheet
} from '@shared/models/interfaces/timesheet.interface';
import { IHoliday } from '@shared/models/interfaces/holiday.interface';
import { IWeekWorktime } from '@shared/models/interfaces/week-worktime.interface';
import { TimesheetStatusEnum } from '@shared/enums/timesheet-status.enum';
import { ITimesheetHistory } from '@shared/models/interfaces/timesheet-history.interface';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {

  private _httpClient: HttpClient = inject(HttpClient);

  getUserTimesheet(userId: number): Observable<ITimesheet> {
    return this._httpClient.get<IResponse<ITimesheet>>(`/timesheet/ricerca/utente/${userId}`).pipe(
      map(({ data }) => data)
    );
  }

  getTimesheet(userId: number, month?: string, year?: string): Observable<ITimesheet> {
    let params: HttpParams = new HttpParams()
      .set('idUtente', userId);
    if(!!month) params = params.set('mese', month);
    if(!!year) params = params.set('anno', year);

    return this._httpClient.get<IResponse<ITimesheet>>(`/timesheet/ricerca`, { params }).pipe(
      map(({ data }) => data)
    );
  }

  getTimesheetById(timesheetId: number): Observable<ITimesheet> {
    return this._httpClient.get<IResponse<ITimesheet>>(`/timesheet/ricerca/${timesheetId}`).pipe(
      map(({ data }) => data)
    );
  }

  getUserHolidays(userId: number, year: string): Observable<IHoliday[]> {
    return this._httpClient.post<IResponse<IHoliday[]>>(`/users/holiday`, { id: userId, anno: Number(year) }).pipe(
      map(({ data }) => data)
    );
  }

  getUserWorktime(userId: number): Observable<IWeekWorktime[]> {
    return this._httpClient.post<IResponse<{ lun: number, mar: number, mer: number, gio: number, ven: number, from: number, to: number }[]>>(`/users/workTime`,[userId]).pipe(
      map(({ data }) => data),
      // TODO: Remove mock
      // map(() => [
      //   {
      //     from: new Date(2019, 9, 1).getTime(),
      //     to: new Date(2024, 9, 31).getTime(),
      //     lun: 0,
      //     mar: 8,
      //     mer: 8,
      //     gio: 8,
      //     ven: 8
      //   },
      //   {
      //     from: new Date(2024, 10, 1).getTime(),
      //     lun: 6,
      //     mar: 6,
      //     mer: 6,
      //     gio: 0,
      //     ven: 6
      //   }
      // ]),
      map(worktimes => [...worktimes].sort((prev, curr) => new Date(prev.from).getTime() - new Date(curr.from).getTime())),
      map(worktimes => worktimes.map(({ lun, mar, mer, gio, ven, from: startDate, to: endDate }) => ({
        '0': 16,
        '1': lun,
        '2': mar,
        '3': mer,
        '4': gio,
        '5': ven,
        '6': 16,
        startDate,
        endDate
      })))
    );
  }

  // TODO
  // getExtendedUserWorktime(userId: number) {
  //   return this._httpClient.post<IResponse<{ lun: number, mar: number, mer: number, gio: number, ven: number, from: number, to: number }[]>>(`/users/workTime`,[userId]).pipe(
  //     map(({ data }) => data),
  //     map(worktimes => [...worktimes].sort((prev, curr) => new Date(prev.from).getTime() - new Date(curr.from).getTime())),
  //     map(worktimes  => {
  //       const output = [];
  //       worktimes.forEach(({ from: start, to: end }) => {
  //         DateUtils.daysBetween(start, to)
  //       })
  //     })
  //   );
  // }

  // TODO: Add return type
  sendTimesheet(userId: number, month: number, year: number, allegatiTimesheet: IAttachmentTimesheet[]): Observable<unknown> {
    return this._httpClient.post<IResponse>(`/timesheet/invio`, { idUtente: userId, mese: month, anno: year, allegatiTimesheet }).pipe(
      map(({ data }) => data)
    );
  }

  saveTimesheet(userId: number, month: number, year: number, workingDays: IWorkingDayTimesheet<Partial<IOrderTimesheet>>[]): Observable<IResultMessage> {
    const request = {
      idUtente: userId,
      mese: month,
      anno: year,
      giornoTimesheet: workingDays.map(({ giorno, commesseOreTimesheet }) => ({
        giorno,
        commesseOre: commesseOreTimesheet.map(order => ({
          idCommessa: order.id,
          oreLavorate: order.oreLavorate,
          dataInizio: new Date(year, month - 1, giorno, 0, 0, 0).getTime(),
          dataFine: new Date(year, month - 1, giorno, 0 , 0, 0).getTime(),
        }))
      }))
    };

    return this._httpClient.post<IResponse<IResultMessage>>(`/timesheet/save`, request).pipe(
      map(({ data }) => data)
    );
  }

  approveTimesheet(timesheetId: number, note: string): Observable<IResultMessage> {
    const request = {
      idTimesheet: timesheetId,
      statoCode: TimesheetStatusEnum.APPROVED,
      note
    };

    return this._httpClient.post<IResponse<IResultMessage>>(`/timesheet/aggiornamentoStatoTimesheet`, request).pipe(
      map(({ data }) => data)
    );
  }

  rejectTimesheet(timesheetId: number, note: string): Observable<IResultMessage> {
    const request = {
      idTimesheet: timesheetId,
      statoCode: TimesheetStatusEnum.REJECTED,
      note
    };

    return this._httpClient.post<IResponse<IResultMessage>>(`/timesheet/aggiornamentoStatoTimesheet`, request).pipe(
      map(({ data }) => data)
    );
  }

  getTimesheetHistory(timesheetId: number): Observable<ITimesheetHistory[]> {
    return this._httpClient.get<IResponse<{ storicoTimesheet: ITimesheetHistory[] }>>(`/timesheet/storicoTimesheet/${timesheetId}`).pipe(
      map(({ data }) => data.storicoTimesheet)
    );
  }

  getTimesheetDocuments(idTimesheet: number): Observable<IDocumentHistoryTimesheet[]> {
    return this._httpClient.get<IResponse<IDocumentHistoryTimesheet[]>>(`/timesheet/${idTimesheet}/allegati`).pipe(
      map(({ data }) => data)
    );
  }

  getTimesheetMonthMealVouchers(month: number, year: number): Observable<IMealVoucher[]> {
    return this._httpClient.get<IResponse<IMealVoucher[]>>(`/timesheet/elencoBuoni/${month}/${year}`).pipe(
      map(({ data }) => data));
  }

  getTimesheetMonthStatus(month: number, year: number): Observable<ITimesheetStatus> {
    return this._httpClient.get<IResponse<ITimesheetStatus>>(`/timesheet/status/${year}/${month}`).pipe(
      map(({ data }) => data));
  }

}
